import PageHead from "../Head";
import HeaderStyleTen from "@/components/Header/HeaderStyle-Ten";
import Separator from "@/components/Common/Separator";
import MainDemo from "@/components/01-Main-Demo/01-Main-Demo";
import MobileMenu from "@/components/Header/MobileMenu";
import Cart from "@/components/Header/Offcanvas/Cart";

import Context from "@/context/Context";
import { Provider, useSelector } from "react-redux";
import Store from "@/redux/store";
import FooterThree from "@/components/Footer/Footer-Three";
import Marketplace from "@/components/12-Marketplace/12-Marketplace";
import FooterOne from "@/components/Footer/Footer-One";

const Home = () => {
  // const { details } = useSelector((state) => state.CompanyReducer);
  return (
    <>
      <PageHead title={`Sikho Kamana Courses`} />

      <Provider store={Store}>
        <Context>
          <MobileMenu />
          <HeaderStyleTen headerSticky="rbt-sticky" headerType="" />
          <Marketplace />
          <Cart />
          <Separator />
          <FooterThree />{" "}
        </Context>
      </Provider>
    </>
  );
};

export default Home;
